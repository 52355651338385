import { Style } from '@/includes/types/Post.types'
import { Advert } from '@/includes/logic/Ads/types'
import { BoardPmConfig, UserActivationActionFlow, UserActivationActionMessage } from './PmConfig.types'
import { LimitedSubscriptionConfig, LimitedSubscriptionType } from '@/includes/types/Channel.types'
import { FaceControlStructure } from '@/includes/types/FaceControl.types'
import { ApproveBalancer } from '../logic/ApproveBalancer/types'
import { InviteLinkApproveType } from '@/includes/logic/InviteLinks/types'

import { BotProperty, Property } from 'piramis-base-components/src/components/BotProperties/components/Properties/types'
import { MessageEditorWithMediaData } from 'piramis-base-components/src/components/MessageEditorWithMedia/types'

export type Permissions = Record<'create' |
    'hold' |
    'view' |
    'edit' |
    'access_published' |
    'limit_published', boolean>

export type BoardAdminPermissions = Permissions | null

export type BoardAdmin = {
    user_id: number,
    username?: string,
    name: string
    channel_ids: Array<number> | null
    limits: BoardAdminPermissions
}

export type BoardPermissions =
        "ChangeConfig" |
        "CreatePost" |
        "IgnoreHold" |
        "ViewOtherPosts" |
        "EditPosts" |
        "AccessPublishedPosts" |
        "IgnorePublishedPostsAccessLimits"

export type Board = {
    board: string
    title: string
    avatar: string
    timezone: string
    lang: LangCode
    targets: Array<string>,
    test_state: TestBoardStateCode
    license_time: string,
    license_type: number,
    /** был ли активировна пробный Prime */
    license_activated: boolean,
    license_expired: boolean,
    config_version: number,
    features_version: number,
    permissions: Array<BoardPermissions>,
    channels: Array<ChannelInfo>,
    weight: number,
    properties: Array<BotProperty>,
    config: BoardConfig
    admins: Array<BoardAdmin>,
}

export type SlimBoardInfo = Omit<Board, 'config' | 'admins'>

export type BoardConfig = {
    styles: Record<string, Style> | null
    pm_config: BoardPmConfig
    editor_hold_time:number
    editor_max_post_access_time:number
    /**
     @deprecated
     */
    properties: Array<Omit<Property, 'group'>>
    labels: Record<number, string>
    ads: Array<Advert>
}

export type BoardWithStatistics = Omit<SlimBoardInfo, 'weight' | 'targets'>
    & { targets_stat: Array<ChannelInfoStatistics> }
    & { targets: Array<ChannelInfoStatistics> }

export type BoardsStat = Array<BoardWithStatistics>

export type BoardAdmins = Pick<Board, 'admins'>

export enum TargetState {
    Board = 'Board',
    Log = 'Log',
    None = 'None',
    BotLeft = 'BotLeft'
}

export type ChannelInfo = {
    id: number,
    title: string,
    username?: string
    members_count: number
    linked_chat_id: number | null
    linked_chat_username: string
    license_type: number
    license_time: string
    avatar: string,
    state: TargetState
    test_state: TestChannelStateCode
    limited_subscription_type: LimitedSubscriptionType
    licence_expired: boolean
    free_licence: boolean
    config_version: number
    config: ChannelConfig
}

export type SlimChannelInfo = Omit<ChannelInfo, 'config'>

export type ChannelInfoStatistics = Omit<ChannelInfo, 'config'> & ChannelStatistics

export type ChannelStatistics = {
    summary_stat?: SummaryStat
    detailed_stat: Array<DetailedStat>
}

export type SummaryStat = {
    first_enter: number
    first_leave: number
    all_enter: number
    all_leave: number
}

export type DetailedStat = {
    channel_id: number
    time: string
} & SummaryStat

export type ChannelConfig = {
    auto_attack_mode_count: number
    auto_attack_mode_interval: number
    kick_deleted_accounts: boolean
    pm_reports: boolean
    replace_channel_forwards: boolean
    disable_join_to_group: boolean
    replace_channel_list: { type: 'Whitelist' | 'Blacklist', items: Array<string> }
    replace_user_forwards: boolean
    album_post_style: string
    media_post_style: string
    text_post_style: string
    slots: Array<Slot>
    goodbuy_message: Array<MessageEditorWithMediaData>
    goodbuy_action?: UserActivationActionFlow | UserActivationActionMessage | null
    limited_subscription_type: LimitedSubscriptionType
    limited_subscription_config?: LimitedSubscriptionConfig | null
    auto_accepted_user_message: Array<MessageEditorWithMediaData>
    approve: InviteLinkApproveType
    user_accept_action?: UserActivationActionFlow | UserActivationActionMessage | null
    user_reject_action?: UserActivationActionFlow | UserActivationActionMessage | null
    user_captcha_success_action?: UserActivationActionFlow | UserActivationActionMessage | null
    face_control?:FaceControlStructure | null
    force_approve: boolean
    approve_balancer: ApproveBalancer
}

type LangCode = 'ru' | 'en'

export enum TestBoardStateCode {
    Success = 'Success',
    None = 'None',
    BotDomainError = 'BotDomainError',

    UndefinedError = 'UndefinedError',
    HandlerError = 'HandlerError',
    ApiError = 'ApiError',
    CommunicationError = 'CommunicationError',
}

export enum TestChannelStateCode {
    Success = 'Success',
    None = 'None',
    ChannelPermissionDeleteMessageError = 'ChannelPermissionDeleteMessageError',
    ChannelPermissionEditMessageError = 'ChannelPermissionEditMessageError',
    ChannelPermissionAddUsersError = 'ChannelPermissionAddUsersError',
    ConnectedGroupNotFoundError = 'ConnectedGroupNotFoundError',
    ConnectedGroupPermissionSendMessageError = 'ConnectedGroupPermissionSendMessageError',
    ConnectedGroupPermissionPinMessageError = 'ConnectedGroupPermissionPinMessageError',
    ConnectedGroupPermissionDeleteMessageError = 'ConnectedGroupPermissionDeleteMessageError',
    ConnectedGroupPermissionEditMessageError = 'ConnectedGroupPermissionEditMessageError',

    UndefinedError = 'UndefinedError',
    ApiError = 'ApiError',
    ChannelNotFoundError = 'ChannelNotFoundError',
    ChannelPermissionSendMessageError = 'ChannelPermissionSendMessageError',
}

export type ChannelSlot = {
    date: string
    slot: Slot
}

export type Slot = {
    time: string
    active_day_of_week: number
    allow_styles: Array<string>
    ads_only: boolean
}

