import BaseApi from '@/includes/services/BaseApi'
import {
    InviteLinkStatReq,
    InviteLinksWithStat,
    InviteLinkToCreate,
    InviteLinkToEdit,
    InviteLinkWithKey
} from '@/includes/logic/InviteLinks/types'

async function reqGetInviteLinks(type: string, body: { board_key: string, target_id: number }): Promise<{ links: Array<InviteLinkWithKey> }> {
    return BaseApi.sendRequest(type, 'getinvitelinks', body)
}

async function reqCreateInviteLink(type: string, body: { board_key: string, target_id: number } & InviteLinkToCreate): Promise<{ link: InviteLinkWithKey }> {
    return BaseApi.sendRequest(type, 'createinvitelink', body)
}

async function reqSetInviteLink(type: string, body: { board_key: string, link_key: string } & InviteLinkToEdit): Promise<{ link: InviteLinkWithKey }> {
    return BaseApi.sendRequest(type, 'setinvitelink', body)
}

async function reqDeleteInviteLink(type: string, body: { board_key:string, link_key:string }): Promise<void> {
    return BaseApi.sendRequest(type, 'deleteinvitelink', body)
}

async function reqGetInviteLinksStat(type: string, body: { board_key: string, target_id: number } & InviteLinkStatReq): Promise<{links: InviteLinksWithStat}> {
    return BaseApi.sendRequest(type, 'getinvitelinksstat', body)
}

export {
    reqGetInviteLinks,
    reqCreateInviteLink,
    reqSetInviteLink,
    reqDeleteInviteLink,
    reqGetInviteLinksStat
}