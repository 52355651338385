import { RootState } from '@/store/state'

export default {
	/**
	 * @deprecated
	 *
	 */
	properties(state: RootState): any {
		return [
			...state.boardsState.activeBoard!.config.properties.map((prop) => Object.assign(prop, { group: 'custom' })),
			...state.AdminTemplate.runtime_config!.system_properties.map((prop) => Object.assign(prop, { group: 'system' })),
		]
	},

	propertiesList(state: RootState) {
		return [
			...(state.boardsState?.activeBoard?.properties ?? []).map((prop) => Object.assign(prop, { group: 'custom' })),
			...state.AdminTemplate.runtime_config!.system_properties.map((prop) => Object.assign(prop, { group: 'system' })),
		]
	},

	labels(state: RootState): any {
		return state.boardsState.activeBoard!.config.labels
	},
}
