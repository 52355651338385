import { RootState } from '@/store/state'
import { errorNotification, successNotification } from '@/includes/services/NotificationService'
import { InviteLinkToCreate, InviteLinkToEdit, InviteLink } from '@/includes/logic/InviteLinks/types'
import { reqCreateInviteLink, reqDeleteInviteLink, reqGetInviteLinks, reqSetInviteLink } from '@/includes/Api/InviteLinks.api'

import { Commit } from 'vuex'

export default {
	getInviteLinks({ commit, state }: { commit: Commit, state: RootState }) {
			return reqGetInviteLinks('tg', { board_key: state.boardsState.activeBoard!.board, target_id: state.channelsState.activeChannel!.id })
				.then(({ links }) => {
					commit('SET_INVITE_LINKS_TO_STORE', links)

					return links
				})
				.catch((error) => {
					errorNotification(error)

					return []
				})
	},
	deleteInviteLink({ commit, state }: { commit: Commit, state: RootState }, key: string) {
		return reqDeleteInviteLink('tg', {
			board_key: state.boardsState.activeBoard!.board,
			link_key: key
		})
			.then(() => {
				commit('DELETE_INVITE_LINK', key)

				successNotification()

				return true
			})
			.catch((error) => {
				errorNotification(error)
				return false
			})
	},
	createInviteLink({ commit, state }: { commit: Commit, state: RootState }, link: InviteLinkToCreate): Promise<InviteLink> {
		return new Promise(resolve => {
			reqCreateInviteLink('tg', {
				board_key: state.boardsState.activeBoard!.board,
				target_id: state.channelsState.activeChannel!.id,
				...link
			})
				.then(({ link }) => {
					commit('ADD_INVITE_LINK', link)
					resolve(link)
				})
				.catch(errorNotification)
		})
	},
	editInviteLink({ commit, state }: { commit: Commit, state: RootState }, { link, link_key }: { link: InviteLinkToEdit, link_key: string }): Promise<InviteLink> {
		return new Promise((resolve) => {
			reqSetInviteLink('tg', {
				board_key: state.boardsState.activeBoard!.board,
				link_key,
				...link
			})
				.then(({ link }) => {
					commit('UPDATE_INVITE_LINK', link)
					resolve(link)
				})
				.catch(errorNotification)
		})
	},
	getInviteLinkModel({ state }: { state: RootState }, key: string) {
			const fullLink = state.inviteLinksState.links!.find((l) => l.key === key)

			if (fullLink) {
				const { link, enter, leave, kick, key, ...otherFields } = fullLink

				return otherFields as InviteLinkToEdit
			}
	},
	resetInviteLinks({ commit }: {commit: Commit}) {
		commit('CLEAR_INVITE_LINKS')
	}
}
