import { PollPost, QuizPost, PostMessage, Schedule, SplitType, PostType, PostState, PayedPostMessage } from 'piramis-base-components/src/shared/modules/posting/types'
import {
    IMessageSettings
} from 'piramis-base-components/src/components/NewMessageEditors/NewMessageEditorWithMedia/Body/logic/types'
import {
    MessageMedia
} from 'piramis-base-components/src/components/NewMessageEditors/NewMessageEditorWithMedia/Media/types'
import {
    MessageButtons
} from 'piramis-base-components/src/components/NewMessageEditors/NewMessageEditorWithMedia/ButtonsConstructor/logic/types'

export type OrderedPostData = CreatedPost & { stat: Array<PostDataStat> }

export type PostDataStat = {
    channel_id: number | null
    chat_id: number | null
    comments_count: number
    comments_link: string
    first_12_hour_forwards: number
    first_12_hour_views: number
    first_24_hour_forwards: number
    first_24_hour_views: number
    first_4_hour_forwards: number
    first_4_hour_views: number
    first_7_days_forwards: number
    first_7_days_views: number
    first_hour_forwards: number
    first_hour_views: number
    forwards: number
    post_link: string
    views: number
}

export type CreatedPost = {
    key: string
    post: {
        message: PostMessage | PollPost | QuizPost | PayedPostMessage
        delayed_actions: Array<PostAction> | null
        reaction_actions: Array<PostReaction> | null
    }
    schedule: Schedule
    owner_id?: number
    description: string | null
    state: PostState
    targets: Array<number>
    run_time: Array<string> | string
    price: number
    notify: Array<any>
    color: string
    split_type: SplitType
    has_posts_outside_interval: boolean
    is_partial_access: boolean

    category?: null
    creative_id?: null
    tags?: Array<any>
}

export type CalendarPosts = CreatedPost & { postTime: string }

export type RequestPost = Pick<CreatedPost, 'post' | 'schedule' | 'targets' | 'tags' | 'description'> & {
    board_key?: string
    style: Style
    origin?: number | null
}
export type RequestPostDraft = { board_key: string, message: PostMessage | PollPost | QuizPost | PayedPostMessage }

export type OriginalPostResponse = {
    message: CreatedPost['post'],
    target: {
        targets: Array<number>,
        original: number | null,
        user_data: {
            post_price: number
            disable_comments: boolean
        },
    },
    schedule: Schedule,
    style: Style
    tags: Array<string>
    description: string
    state: PostState
}

export enum PostCommentsStateTypes {
    None = 'None',
    Disabled = 'Disabled',
    Linear = 'Linear'
}

export type StyleData = {
    post_price: number
    disable_comments?: boolean
    color: string | null
    post_comments_state: PostCommentsStateTypes
    targets?: Array<number>
}

export type Style = {
    id?: number | null,
    compress_level: null | 4320 | 2160 | 1440 | 1080 | 720 | 576 | 480 | 360 | 240
    watermark_type?: WatermarkType | null
    watermark_data?: any
    watermark_position?: WatermarkPosition | null
    prefix_message?: Array<MessageVariants> | null
    postfix_message?: Array<MessageVariants> | null
    actions: Array<PostAction>
    protect_content?: boolean | null
    disable_notify?: boolean | null
    disable_link_preview?: boolean | null
    pin?: boolean | null
    data?: StyleData | null
}

export enum WatermarkType {
    None = 'None',
    File = 'File',
    Text = 'Text'
}

export enum WatermarkPosition {
    Flip = 'Flip',
    Center = 'Center',
    TopRight = 'TopRight',
    TopLeft = 'TopLeft',
    BottomRight = 'BottomRight',
    BottomLeft = 'BottomLeft',
}

export type Conditions = {
    type: string
    values: any
}

export type Period = {
    interval: number | null
    unit: string
    conditions: Array<Conditions> | null
}

export type PostAction = {
    delay: number,
    execute_type: ActionExecuteType
} & IPostActionMessage

export type PostReaction = {
    reaction: string,
    lower_bound: number,
} & IPostActionMessage

export type IPostActionMessage = {
    action: PostActions
}

export enum ActionExecuteType {
    Serial = 'Serial',
    Parallel = 'Parallel'
}

export enum ForwarMessageActionMode {
    Forward = 'Forward',
    Copy = 'Copy'
}

export type PostActions =
      SendCommentAction
    | EditPostAction
    | SendReplyCommentAction
    | SendReplyMessageAction
    | NotifyUserAction
    | NotifyUsersAction
    | ForwardMessageAction
    | ForwardAction
    | DeletePostAction
    | PinMessageAction
    | PinSilentMessageAction
    | UnpinMessageAction
    | PinChatMessageAction
    | PinChatSilentMessageAction
    | UnpinChatMessageAction

export type SendCommentAction = {
    type: PostActionType.SendCommentAction,
    message: SendCommentActionMessageObject
}

export type EditPostAction = {
    type: PostActionType.EditPostAction,
    message: SendCommentActionMessageObject
}

export type SendReplyCommentAction = {
    type: PostActionType.SendReplyCommentAction,
    message: PostMessage
}

export type SendReplyMessageAction = {
    type: PostActionType.SendReplyMessageAction,
    message: PostMessage
}

export type NotifyUserAction = {
    type: PostActionType.NotifyUserAction
    message:string
    users: Array<number>
}

export type NotifyUsersAction = {
    type: PostActionType.NotifyUsersAction
    message:Array<MessageVariants>
    users: Array<number>
}

export type ForwardMessageAction = {
    type: PostActionType.ForwardMessageAction
    mode: ForwarMessageActionMode,
    targets:  Array<number>
}

export type DeletePostAction = {
    type: PostActionType.DeletePostAction
}

export type PinMessageAction = {
    type: PostActionType.PinMessageAction
}

export type PinSilentMessageAction = {
    type: PostActionType.PinSilentMessageAction
}

export type UnpinMessageAction = {
    type: PostActionType.UnpinMessageAction
}
export type PinChatMessageAction = {
    type: PostActionType.PinChatMessageAction
}

export type PinChatSilentMessageAction = {
    type: PostActionType.PinChatSilentMessageAction
}

export type UnpinChatMessageAction = {
    type: PostActionType.UnpinChatMessageAction
}

export type ForwardAction = {
    type: PostActionType.ForwardAction
    targets: Array<ForwardActionTarget>
}

export type ForwardActionTarget = {
    mode: ForwarMessageActionMode
    id: number
    topic: number | null
}

//  SendCommentAction = "SendCommentAction",
//  EditPostAction = "EditPostAction",
//  DeletePostAction = "DeletePostAction",
//  UnpinChatMessageAction = "UnpinChatMessageAction"

export enum PostActionType {
    SendCommentAction = 'SendCommentAction',
    EditPostAction = 'EditPostAction',
    SendReplyCommentAction = 'SendReplyCommentAction',
    SendReplyMessageAction = 'SendReplyMessageAction',

    //
    NotifyUserAction = 'NotifyUserAction',
    NotifyUsersAction = 'NotifyUsersAction',
    //

    ForwardMessageAction = 'ForwardMessageAction',
    ForwardAction = 'ForwardAction',

    DeletePostAction = 'DeletePostAction',
    PinMessageAction = 'PinMessageAction',
    PinSilentMessageAction = 'PinSilentMessageAction',
    UnpinMessageAction = 'UnpinMessageAction',
    PinChatMessageAction = 'PinChatMessageAction',
    PinChatSilentMessageAction = 'PinChatSilentMessageAction',
    UnpinChatMessageAction = 'UnpinChatMessageAction'
}

export type SendCommentActionMessageObject = {
    type: PostType.Post,
    variants: Array<Omit<IMessageSettings & MessageMedia & MessageButtons, 'pin' | 'remove_previous' | 'disable_notify'>>
}

export type Attachments = {
    type: string
    file: string
}

export type MessageVariants = IMessageSettings & MessageMedia & MessageButtons

export enum ScheduleType {
    Suggest = 'Suggest',
    Schedule = 'Schedule',
    PostNow = 'PostNow',
    Draft = 'Draft',
    Test = 'Test'
}

export type PickerColors = { color: string, className: string }

export const ColorPickerColors: Array<PickerColors> = [
    { color: '#EECF6D', className: 'yellow' },
    { color: '#8B6220', className: 'brown' },
    { color: '#048A81', className: 'cyan' },
    { color: '#FFB7C3', className: 'pink' },
    { color: '#171d1c', className: 'black' },
    { color: '#b1a7a6', className: 'gray' }
]

export enum ProcessSuggestPostAction {
    Accept  = 'Accept',
    AcceptWithSlot = 'AcceptWithSlot',
    Reject = 'Reject'
}

export enum OrderedPostType {
    Active = 'Active',
    ActiveAndCompleted = 'ActiveAndCompleted',
    Deleted = 'Deleted',
    Suggest = 'Suggest'
}