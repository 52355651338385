import { PollPost, PostMessage, QuizPost } from 'piramis-base-components/src/shared/modules/posting/types'

export type DraftsState = {
    drafts: null | Array<{ key: Record<string, any>, message: PostMessage | PollPost | QuizPost }>
    loading: boolean
}

export const draftsState: DraftsState = {
    drafts: null,
    loading: false
}
